<template>
	<div class="page container">
		<v2-back-btn :route="`/teaching`" />
		<div class="page__info">
			<div class="page__info--title">
				Payment settings
			</div>
		</div>

		<div>
			<div v-if="!linkedAccount">
				<p>
					<strong>You have not linked an account</strong>
				</p>
			</div>
			<list-view v-if="linkedAccount" :list="getLinkedAcccountList" />
			<actions :actions="getActions" @actionClick="onActionClick" />
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isTeacherOnly": true
		}
	}
</route>

<script>

	import api          from '@/services/api';
	import ListView     from '@/components/ui/views/listView/ListView';
	import Actions      from '@/components/ui/Actions';
	import actionClick  from '@/mixins/actionClick';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';

	export default {
		metaInfo: {
			title: 'Payment settings'
		},
		layout: 'v2default',
		components: {
			ListView,
			Actions,
			V2BackBtn
		},
		mixins: [actionClick],
		data: () => ({
			linkedAccount: false
		}),
		computed: {
			getLinkedAcccountList () {
				return [
					(this.linkedAccount?.account && this.linkedAccount.account.allowPayout) ? {
						text: `Bridge128's commission: ${this.linkedAccount.account.commissionRate * 100}%`
					} : false,
					((this.linkedAccount?.account && this.linkedAccount.account.allowPayout) ?
						{
							text: `Bridge128's minimum fee: £${this.linkedAccount.account.minFee / 100}`
						} : false
					),
					{
						text: 'Your payout will be [course cost] minus [Bridge128’s commission] or minus the minimum fee, whichever is greater'
					},
					(this.linkedAccount?.stripeAccount) ? {
						text: `Stripe account ID: ${this.linkedAccount.stripeAccount.id}`
					} : false,
					(this.linkedAccount?.stripeAccount) ? {
						text: `Stripe account Type: ${this.linkedAccount.stripeAccount.type}`
					} : false,
					(this.linkedAccount?.stripeAccount?.detailsSubmitted) ? {
						text: 'Stripe account activated'
					} :  false,
					(this.linkedAccount.stripeAccount?.settings?.payouts && this.linkedAccount?.account?.allowPayout) ? {
						text: `Your payouts are sent ${this.linkedAccount.stripeAccount.settings.payouts.schedule.interval}, ${this.linkedAccount.stripeAccount.settings.payouts.schedule.delayDays} days after payment.`
					} : false,
					(!this.linkedAccount?.account?.allowPayout) ? {
						text: 'You account does not receive payouts'
					} : false
				].filter(Boolean);
			},
			getPayoutIsAllowed () {
				return this.$store.getters['auth/getPayoutIsAllowed'];
			},
			getActions () {
				if (!this.getPayoutIsAllowed) {
					return {};
				}
				return {
					primary: [
						(this.linkedAccount.stripeAccount?.detailsSubmitted) ? {
							text : 'Open Stripe Dashboard',
							actionId: 'openDashboard'
						} : (this.linkedAccount.stripeAccount) ? {
							text : 'Complete Stripe setup',
							actionId: 'linkNewAccount'
						} : {
							text : 'Link new Stripe account',
							actionId: 'linkNewAccount'
						}
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/payment',
						text: 'Payment settings'
					}
				];
			}
		},
		created () {
			this.getLinkedAccount();
		},
		methods: {
			onActionClick (actionId) {
				if (!this[actionId] ||
						typeof this[actionId] !== 'function') {
					return;
				}
				this[actionId]();
			},
			async getLinkedAccount () {
				const response = await api.teacherPayouts.getLinkedAccount();
				if (!response) {
					return false;
				}
				this.linkedAccount = response;
				await this.$store.dispatch('auth/refreshToken');
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async openDashboard () {
				const dashboard = await api.teacherPayouts.getAccountDashboard();
				if (!dashboard) {
					return false;
				}
				window.open(dashboard.url, '_blank');
			},
			async linkNewAccount () {
				const account = await api.teacherPayouts.linkAccount();
				if (!account) {
					return false;
				}
				window.location.href = account.accountLinks.url;
			}
		}
	};
</script>
